<template>
	<el-dialog :title="'发文回复'" :close-on-click-modal="false" :visible.sync="visible"
			   width="820px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px">
			<el-row>
				<el-col :span="12">
					<el-form-item label="发文类型">
						{{getDocumentType(dataForm.fileKind2)}}
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="发文编号">
						{{dataForm.fileNo}}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="文件主题">
						{{dataForm.fileTitle}}
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="回复人"  prop="answerFinishMan">
						<el-input class="selItemInput" v-model="dataForm.answerFinishMan" type="input" placeholder="请填写审核人"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="回复时间" prop="answerFinishTime">
						<el-date-picker  class="selItemInput" v-model="dataForm.answerFinishTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary"  @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "superdocument-reply",
		data() {
			return {
				visible: false,
				inline: false,
				documentTypeList: [],
				dataForm: {
					workNo: $common.getItem("workNo"),
					fileId: '',
					fileNo: '',
					dispMan: '',
					fileTitle: '',
					receDispCompany: '',
					filePath: '',
					fileKind2: '',
					dispTime: '',
					closeTime: '',
					answerFinishFlg: '1',
				},
				dataRule: {
					answerFinishMan: [
						{ required: true, message: "请输入回复人", trigger: "blur" }
					],
					answerFinishTime: [
						{ required: true, message: "请选择回复时间", trigger: "blur" }
					],
				},
			}
		},
		methods: {
			init(id) {
				this.getSubjectDropDown();
				this.dataForm = {
					workNo: $common.getItem("workNo"),
					fileId: '',
					fileNo: '',
					dispMan: '',
					fileTitle: '',
					receDispCompany: '',
					filePath: '',
					fileKind2: '',
					dispTime: '',
					closeTime: '',
				};
				this.dataForm.fileId = id?id:0;
				if (this.dataForm.fileId) {
					this.getDetail();
				}
				this.$nextTick(() => {
					this.visible = true;
				});
				this.$refs['dataForm'].resetFields();
			},
			getDetail(){
				this.$http({
					url: this.$store.state.httpUrl + `/business/fileinfo/info/${this.dataForm.fileId}`,
					method: "get",
					params: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataForm = data.body
					}
				});
			},
			getDocumentType(value){
				let name ="";
				this.documentTypeList.forEach(function(item){
					if (value===item.displayValue){
						name = item.displayName;
					}
				})
				return name;
			},
			//获取发文类型
			getSubjectDropDown() {
				this.$http({
					url:  this.$store.state.httpUrl + "/common/getSendDocumentType/"+$common.getItem("workNo")+"/"+$common.getItem("subjectNo"),
					method: "get",
					params: {},
				}).then(({data}) => {
					if (data && data.resultCode === 200) {
						this.documentTypeList = data.body;
					}
				})
			},
			//表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						this.$http({
							url:  this.$store.state.httpUrl + "/business/fileinfo/updateReply",
							method: "post",
							data: this.$http.adornData({
								answerFinishMan: this.dataForm.answerFinishMan,
								answerFinishTime: this.dataForm.answerFinishTime,
								fileId: this.dataForm.fileId
							}
							)
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$parent.getDataList();
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			}
		}
	}
</script>
<style scoped>
	.selItemInput{
		width: 200px !important;
	}
</style>
